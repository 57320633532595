import * as React from 'react';

function SvgPlayIcon(props) {
  return (<svg viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#play-icon_svg__a)">
      <circle cx={76} cy={68} r={64} fill="#F26436" />
      <path
        d="M106.086 66.274c1.319.773 1.319 2.68 0 3.452L59.264 97.154c-1.334.78-3.011-.18-3.011-1.726V40.572c0-1.545 1.677-2.507 3.01-1.726l46.823 27.428Z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="play-icon_svg__a"
        x={0}
        y={0}
        width={152}
        height={152}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={8} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.109804 0 0 0 0 0.341176 0 0 0 0.5 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1056_14261" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1056_14261" result="shape" />
      </filter>
    </defs>
  </svg>)
}
export default SvgPlayIcon;
