import * as React from 'react';

function SvgVideoTopCorner(props) {
  return (<svg viewBox="0 0 215 224" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M209.474 181.045V25.471C209.474 12.503 198.862 2 185.76 2H0"
      stroke="url(#video-top-corner_svg__a)"
      strokeWidth={4}
      strokeMiterlimit={10}
    />
    <path d="M214.217 206.12h-10.059v17.701h10.059V206.12Z" fill="#00544B" />
    <path
      d="M214.217 188.262h-10.059v13.398h10.059v-13.398ZM214.217 210.599h-10.059v4.459h10.059v-4.459Z"
      fill="#002D26"
    />
    <defs>
      <linearGradient
        id="video-top-corner_svg__a"
        x1={213.426}
        y1={89.566}
        x2={0}
        y2={89.566}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#C9D688" />
        <stop offset={0.69} stopColor="#FF8C1A" />
        <stop offset={1} stopColor="#F46437" />
      </linearGradient>
    </defs>
  </svg>)
}
export default SvgVideoTopCorner;
