import * as React from 'react';

function SvgBrokenRings(props) {
  return (<svg viewBox="0 0 268 756" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m232.625 553.681.616-.739c-62.352-52.06-92.503-134.064-78.678-214.02.731-4.226 1.593-8.49 2.555-12.655l-.939-.215c-.97 4.187-1.832 8.46-2.563 12.709-6.967 40.305-3.048 81.726 11.331 119.776 13.963 36.941 37.365 69.841 67.678 95.144ZM183.991 259.898c31.63-52.36 83.959-90.364 143.57-104.25l-.216-.94c-29.874 6.959-57.671 19.668-82.611 37.765-24.972 18.121-45.686 40.637-61.567 66.924l.824.501ZM380.652 627.625c16.496 0 33.046-1.64 49.227-4.873l-.193-.947c-70.849 14.172-144.193-3.087-201.218-47.356l-.592.762c29.112 22.6 61.958 38.165 97.622 46.248a249.98 249.98 0 0 0 52.267 6.15c.962.008 1.917.016 2.879.016h.008Z"
      fill="#00544B"
    />
    <path
      d="m149.821 472.362.893-.362c-33.347-82.011-20.376-176.347 33.847-246.203a246.044 246.044 0 0 1 8.937-10.869l-.724-.632a255.226 255.226 0 0 0-8.968 10.916c-13.871 17.874-25.233 37.441-33.754 58.164a248.157 248.157 0 0 0-16.635 62.844c-5.473 42.676.2 86.299 16.412 126.15l-.008-.008ZM253.332 164.831c57.24-34.193 127.043-43.969 191.504-26.819l.246-.931c-32.3-8.591-65.538-10.592-98.785-5.943-33.292 4.65-64.737 15.711-93.465 32.869l.492.824h.008ZM114.696 647.738l.054-.053C17.96 553.103-21.438 412.974 11.924 281.974a387.041 387.041 0 0 1 5.889-20.691l-.077-.023a386.947 386.947 0 0 0-5.889 20.691C3.341 315.352-.615 349.583.077 383.707a377.786 377.786 0 0 0 14.996 97.861 381.711 381.711 0 0 0 39.088 89.501c16.828 28.066 37.188 53.861 60.535 76.669ZM71.081 154.554C130.43 71.696 222.287 15.395 323.104.077L323.088 0a379.87 379.87 0 0 0-73.628 18.906 382.766 382.766 0 0 0-67.932 32.869 382.643 382.643 0 0 0-60.173 45.609 379.28 379.28 0 0 0-50.351 57.124l.062.046h.015ZM373.878 731.097c19.414 0 38.827-1.616 58.025-4.842 59.85-10.045 116.42-35.532 163.599-73.682l-.053-.062c-63.576 51.421-142.369 78.278-221.902 78.278-42.368 0-84.937-7.613-125.557-23.193a354.055 354.055 0 0 1-18.39-7.652l-.031.077a364.525 364.525 0 0 0 18.398 7.652c29.797 11.431 60.85 18.767 92.31 21.808a350.14 350.14 0 0 0 33.601 1.624v-.008Z"
      fill="#00544B"
    />
    <path
      d="m137.127 639.479.054-.062c-69.795-63.529-111.663-154.1-114.857-248.474h-.077a351.894 351.894 0 0 0 9.437 69.765 354.17 354.17 0 0 0 22.893 66.03 353.84 353.84 0 0 0 35.448 60.266 351.047 351.047 0 0 0 47.102 52.475ZM84.906 178.471a350 350 0 0 1 37.095-44.985 354.277 354.277 0 0 0-37.026 45.024l-.069-.046v.007Zm305.668-150.82c37.349 1.77 74.114 9.506 109.4 23.039l-.031.077c-35.84-13.749-72.705-21.323-109.369-23.117Z"
      fill="#00544B"
    />
  </svg>)
}
export default SvgBrokenRings;
