import React, { useState } from 'react';
import styled from 'styled-components';
import Player from 'react-player/vimeo';
import { AnimatePresence, motion } from 'framer-motion';
import { PlayIcon } from '../../../assets/svgs';

function Video({ title, style, url }) {
  const [playing, setPlaying] = useState(false);
  return (
    <Wrapper style={style} key={url}>
      <Player
        autopause
        autopip
        controls
        playing={playing}
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
        playsinline
        stopOnUnmount={false}
        title={title}
        url={url}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0
        }}
        config={{
          vimeo: {
            playerOptions: {
              autopause: true,
              controls: true,
              color: 'CE9200',
              transparent: false
            }
          }
        }}
      />
      <AnimatePresence>
        {!playing && (
          <Overlay
            onClick={() => setPlaying(true)}
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}>
            <Button
              onClick={() => setPlaying(true)}
              initial={{ scale: 1, filter: 'brightness(100%)' }}
              whileHover={{ scale: 1.075, filter: 'brightness(107.5%)' }}
              whileTap={{ scale: 0.95 }}>
              <StyledPlayIcon />
            </Button>
          </Overlay>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  width: 100%;
  z-index: 10;
  ${({ style }) => style}
`;

const Overlay = styled(motion.div)`
  align-items: center;
  background-color: rgba(000, 000, 000, 0.15);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Button = styled(motion.button)`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 80px;

  @media screen and (min-width: 550px) {
    width: 128px;
    height: 128px;
  }
`;

const StyledPlayIcon = styled(PlayIcon)`
  cursor: pointer;
  width: 100%;
`;

const Shine = styled.div`
  animation: slide 1.75s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  z-index: -1;

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default Video;
