import * as React from 'react';

function SvgDownArrow(props) {
  return (<svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m1.384 1.331 4.2 4.201 4.195-4.29"
      stroke="#F26436"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>)
}
export default SvgDownArrow;
